import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    useDisclosure,
    Input,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Table,
    ModalFooter,
    Textarea,
    User,
    Spinner,
    SelectItem,
    Select
} from "@nextui-org/react";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BaseURL} from "../variables/Variables";
import ExcelSave from "./ExcelSave";

export const ApproveDetailsModal = ({id, open, external, name, approveUI, setItem, setListUpdated, disabled, status}) => {
    const buttonRef = useRef(null);
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [template, setTemplate] = useState([]);
    const [data, setData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [rows, setRows] = useState([]);
    const [AVR, setAVR] = useState(null);
    const columns = [
        {
            key: "id",
            label: "Артикул",
        },
        {
            key: "name",
            label: "Наименование товара",
        },
        {
            key: "count",
            label: "Количество",
        },
        {
            key: "pack",
            label: "Упаковка",
        },
        {
            key: "price",
            label: "Цена за единицу",
        },
        {
            key: "sum",
            label: "Сумма",
        },
        {
            key: "actions",
            label: "Действия",
        }
    ];

    useEffect(() => {
        fetchDetails(id,open,external);
    }, [id, open]);


    const fetchDetails = (id, open, external) => {
        if (id){
            axios.get(`${BaseURL}/users/approve/${id}/details/`).then(
                res => {
                    console.log(res.data);
                    setData(res.data);
                    setUpdatedData(res.data);
                    setRows(res.data.rows);
                    if(open && external){
                        setItem({name: name});
                        buttonRef.current.click();
                    }
                }
            ).catch(
                err => {
                    console.log(err.response.data);
                }
            )
        }
    }

    const accept = () => {
        setIsLoading(true);
        if (status === "Возвращен"){

            const data = new FormData();

            data.append("name", updatedData.name);
            data.append("comment", updatedData.comment);
            data.append("delivery_date", updatedData.delivery_date);
            data.append("supplier", updatedData.supplier);
            data.append("rows", JSON.stringify(rows));
            data.append("template", JSON.stringify(""));

            axios.post(`${BaseURL}/users/approve/${id}/edit/`, data).then(
                res => {
                    window.location.reload();
                    setIsLoading(false);
                    setIsOpen(false);
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
        }else{
            axios.post(`${BaseURL}/users/approve/${id}/accept/`, {"comment": comment}).then(
                res => {
                    window.location.reload();
                    setIsLoading(false);
                    setIsOpen(false);
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
        }
    }

    const sendAVR = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("avr", AVR);
        const headers = {'Content-Type': 'multipart/form-data'};
        axios.post(`${BaseURL}/users/approve/${data.id}/add/avr/`, formData, {headers}).then(
            res => {
                window.location.reload();
                setIsLoading(false);
                setIsOpen(false);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    const deny = () => {
        setIsLoading(true);
        axios.post(`${BaseURL}/users/approve/${id}/deny/`, {"comment": comment}).then(
            res => {
                window.location.reload();
                setIsLoading(false);
                setIsOpen(false);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    const changeInput = (e, index, name) => {
        const temp_rows = [...rows];
        temp_rows[index][`${name}`] = e.target.value;
        if (name === "count" || name === "price"){
            temp_rows[index].sum = temp_rows[index].count * temp_rows[index].price;
        }
        setRows(temp_rows);
    }

    const deleteRow = (index) => {
        const temp_rows = [...rows]
        temp_rows.splice(index, 1);
        setRows(temp_rows);
    }

    return (
        <>
            <Button ref={buttonRef} onPress={() => {setIsOpen(true)}} size={"sm"} className={`font-bold ${external && "hidden"}`}>Подробнее</Button>
            <Modal
                size={"full"}
                isDismissable={false}
                key={id}
                isOpen={isOpen}
                onOpenChange={() => {setIsOpen(false)}}
                placement="top-center"
                className={"relative"}
                onClose={() => {setIsLoading(false);
                    setData({});
                }}
                hideCloseButton={true}
            >
                        <ModalContent className={"pb-[20px] overflow-auto"}>
                            {(onClose) => {
                                return(
                                    <>
                                        {
                                            isLoading &&
                                            <div className={"absolute top-0 right-0 bg-[#FFFFFFB0] w-full h-full z-50 flex justify-center items-center"}>
                                                <Spinner/>
                                            </div>
                                        }
                                        <ModalHeader className="flex flex-row justify-between items-center gap-1"><p>{name}</p>
                                            <Button onPress={() => {setIsOpen(false)}}>Закрыть</Button>
                                        </ModalHeader>
                                        <ModalBody>
                                            {
                                                data.need_avr && <form className={"w-full flex flex-col gap-[16px]"} onSubmit={sendAVR}>
                                                    <p>*Прикрепите АВР</p>
                                                    <Input
                                                        label={""}
                                                        required={true}
                                                        type={"file"}
                                                        name={"comment"}
                                                        onChange={(e) => {
                                                            setAVR(e.target.files[0]);
                                                        }
                                                        }
                                                    />
                                                    <div className={"flex w-full gap-[12px]"}>
                                                        <Button type={"submit"} className={"bg-[#FCE345]"} fullWidth={true}>Отправить АВР</Button>
                                                    </div>
                                                </form>
                                            }
                                            <p>*{updatedData.comment}</p>
                                            {
                                                data.supplier &&
                                                <>
                                                    <Input
                                                        disabled={disabled}
                                                        label={"Поставщик"}
                                                        value={updatedData.supplier}
                                                        required={true}
                                                        onChange={(e) => {setUpdatedData({...updatedData, supplier: e.target.value})}}
                                                    />
                                                    <Textarea
                                                        disabled={disabled}
                                                        label={"Комментарий (При необходимости сотрите и напишите новый)"}
                                                        value={updatedData.comment}
                                                        type={"text"}
                                                        required={true}
                                                        onChange={(e) => {setUpdatedData({...updatedData, comment: e.target.value})}}
                                                    />
                                                    <Input
                                                        disabled={disabled}
                                                        label={"Дата поставки"}
                                                        value={updatedData.delivery_date}
                                                        type={"date"}
                                                        required={true}
                                                        onChange={(e) => {setUpdatedData({...updatedData, delivery_date: e.target.value})}}
                                                    />
                                                    <Input
                                                        disabled={true}
                                                        label={"Общая сумма"}
                                                        value={updatedData.pay_sum.toLocaleString("RU-ru")}
                                                        type={"text"}
                                                        required={true}
                                                    />
                                                </>
                                            }
                                            {
                                                template && template.map((input, index) => (
                                                    <Input
                                                        disabled={disabled}
                                                        label={input.label}
                                                        value={input.value}
                                                        required={input.required}
                                                        key={index}
                                                        type={input.type}
                                                    />
                                                ))
                                            }
                                            {
                                                rows &&
                                                <Table aria-label="Example table with dynamic content">
                                                    <TableHeader columns={columns}>
                                                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                                                    </TableHeader>
                                                    <TableBody>
                                                        {
                                                            rows?.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell className={"rounded-none"}>
                                                                        <Input
                                                                            disabled={disabled}
                                                                            className={"w-[120px]"}
                                                                            required={true}

                                                                            value={row.id}
                                                                            onChange={e => {changeInput(e, index, "id")}}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={"rounded-none"}>
                                                                        <Input
                                                                            multiple={true}
                                                                            disabled={disabled}
                                                                            required={true}
                                                                            value={row.name}
                                                                            onChange={e => {changeInput(e, index, "name")}}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={"rounded-none"}>
                                                                        <Input
                                                                            disabled={disabled}
                                                                            className={"w-[120px]"}
                                                                            required={true}
                                                                            value={row.count}
                                                                            type={"number"}
                                                                            onChange={e => {changeInput(e, index, "count")}}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={"rounded-none"}>
                                                                        {
                                                                            status === "Возвращен" ?
                                                                                <Select
                                                                                    required={true}
                                                                                    value={""}
                                                                                    onChange={e => {
                                                                                        row.pack = e.target.value
                                                                                    }}
                                                                                >
                                                                                    <SelectItem key={"-"} value={"-"}>-</SelectItem>
                                                                                    <SelectItem key={"Услуга"} value={"Услуга"}>Услуга</SelectItem>
                                                                                    <SelectItem key={"г."} value={"г."}>г.</SelectItem>
                                                                                    <SelectItem key={"Кг."} value={"Кг."}>Кг.</SelectItem>
                                                                                    <SelectItem key={"Шт."} value={"Шт."}>Шт.</SelectItem>
                                                                                    <SelectItem key={"Литр"} value={"Литр"}>Литр</SelectItem>
                                                                                    <SelectItem key={"Банка"} value={"Банка"}>Банка</SelectItem>
                                                                                    <SelectItem key={"Упаковка"} value={"Упаковка"}>Упаковка</SelectItem>
                                                                                    <SelectItem key={"Пачка"} value={"Пачка"}>Пачка</SelectItem>
                                                                                    <SelectItem key={"м"} value={"м"}>Метр</SelectItem>
                                                                                    <SelectItem key={"м2"} value={"м2"}>Квадратный метр</SelectItem>
                                                                                    <SelectItem key={"Пог. м"} value={"Пог. м"}>Погонный метр</SelectItem>
                                                                                </Select>
                                                                                :
                                                                                <Input
                                                                                    disabled={disabled}
                                                                                    className={"w-[120px]"}
                                                                                    required={true}
                                                                                    value={row.pack}
                                                                                    // onChange={e => {changeInput(e, index, "pack")}}
                                                                                />
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className={"rounded-none"}>
                                                                        {
                                                                            status === "Возвращен" ?
                                                                                <Input
                                                                                    disabled={disabled}
                                                                                    className={"w-[120px]"}
                                                                                    required={true}
                                                                                    value={row.price}
                                                                                    onChange={e => {changeInput(e, index, "price")}}
                                                                                    type={"number"}
                                                                                />
                                                                                :
                                                                                <Input
                                                                                    disabled={disabled}
                                                                                    className={"w-[120px]"}
                                                                                    required={true}
                                                                                    value={row.price}
                                                                                    type={"number"}
                                                                                />
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className={"rounded-none"}>
                                                                        <Input
                                                                            disabled={disabled}
                                                                            className={"w-[120px]"}
                                                                            value={row.sum}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={"rounded-none"}>
                                                                        {
                                                                            index > 0 && <Button onPress={() => {deleteRow(index)}}>Удалить</Button>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            }
                                        </ModalBody>
                                        <div className={"flex flex-col gap-[12px] max-w-full px-[16px]"}>
                                            <div>
                                                <ExcelSave url={data.download_url} name={`${data.name}_${data.id}_${data.created_at}`}/>
                                            </div>
                                            {
                                                data?.attachment &&
                                                <a className="z-0 group md:max-w-[300px] relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 text-small gap-2 rounded-medium w-full [&>svg]:max-w-[theme(spacing.8)] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover"
                                                   download={true} href={`${data.attachment}`} target={"_blank"}>
                                                    Скачать закрепленный файл
                                                </a>
                                            }
                                            {
                                                data?.pay_bill &&
                                                <a className="z-0 group md:max-w-[300px] relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 text-small gap-2 rounded-medium w-full [&>svg]:max-w-[theme(spacing.8)] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover" download={true} href={`${data.pay_bill}`} target={"_blank"}>
                                                    Скачать счет на оплату
                                                </a>
                                            }
                                            {
                                                data?.avr_bill &&
                                                <a className="z-0 group md:max-w-[300px] relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 text-small gap-2 rounded-medium w-full [&>svg]:max-w-[theme(spacing.8)] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover" download={true} href={`${data.avr_bill}`} target={"_blank"}>
                                                    Скачать АВР
                                                </a>
                                            }
                                            {
                                                data?.attachments?.length > 0 &&
                                                <div className={"flex flex-col gap-[12px] w-full mb-[20px]"}>
                                                    <h2>Закрепленные файлы (Нажмите чтобы скачать)</h2>
                                                    <div className={"overflow-auto flex flex-row gap-[16px] w-full"}>
                                                        {
                                                            data.attachments.map((attachment, index) => (
                                                                <a key={index} download={true} target={"_blank"} href={attachment.link} className="bg-gray-400 text-white p-[4px] rounded-[4px]">
                                                                    {attachment.name}
                                                                </a>
                                                            ))
                                                        }
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        <div className={"min-w-[100px] min-h-[240px] overflow-auto mr-[20px]"}>
                                            {
                                                status !== "Возвращен" &&
                                                <>
                                                    <div className={"px-[26px] py-[10px] gap-[12px] flex flex-row"}>
                                                        {
                                                            data?.persons && Object.entries(
                                                                data?.persons?.reduce((acc, person) => {
                                                                    // Группируем по position
                                                                    if (!acc[person.position]) acc[person.position] = [];
                                                                    acc[person.position].push(person);
                                                                    return acc;
                                                                }, {})
                                                            ).map(([position, persons]) => (
                                                                <div key={position} className="flex flex-col items-center gap-[8px]">
                                                                    <div className="w-full border-t border-yellow-400 border-[4px] text-center text-[12px] text-gray-700 mb-[8px]">
                                                                    </div>
                                                                    <div className="flex flex-row gap-[12px]">
                                                                        {persons.map((person, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className={
                                                                                    "flex flex-col justify-start items-center gap-[8px] border-gray-500 border rounded-md"
                                                                                }
                                                                            >
                                                                                <img
                                                                                    draggable={false}
                                                                                    src={person.user.avatar}
                                                                                    className={
                                                                                        "w-full h-[60px] min-w-[40px] min-h-[40px] object-cover bg-gray-400 border-[green] rounded-t-md"
                                                                                    }
                                                                                />
                                                                                <p className={"text-[12px] max-w-[80px] text-center p-[4px]"}>
                                                                                    {person.user.username}
                                                                                </p>
                                                                                <p
                                                                                    className={`text-[12px] max-w-[80px] text-center p-[4px] ${
                                                                                        person.is_approved
                                                                                            ? "bg-green-500 rounded-b-md"
                                                                                            : "rounded-b-md"
                                                                                    }`}
                                                                                >
                                                                                    {person.is_approved && person.updated_at}
                                                                                </p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <p className={"text-[12px] px-[26px]"}>Аватары утвердивших помечены зеленым цветом</p>
                                                </>
                                            }
                                        </div>
                                        {
                                            approveUI &&
                                            <ModalFooter className={"flex flex-col w-full"}>
                                                {status !== "Возвращен" ?
                                                    <Textarea
                                                        onChange={(e) => {setComment(e.target.value)}}
                                                        label={"Комментарий"}
                                                        placeholder={"Ваш комментарий (Можно оставить пустым)"}
                                                    /> :
                                                    <Button onPress={() => {
                                                        const temp_rows = [...rows]
                                                        temp_rows.push({
                                                            "id": "",
                                                            "name": "",
                                                            "pack": "",
                                                            "count": 0,
                                                            "price": 0,
                                                            "sum": ""
                                                        });
                                                        setRows(temp_rows);
                                                    }}>
                                                        Добавить строчку
                                                    </Button>
                                                }
                                                <div className={"flex w-full gap-[12px]"}>
                                                    {status !== "Возвращен" && <Button onPress={deny} fullWidth={true}>Вернуть</Button>}
                                                    <Button onPress={accept} className={"bg-[#FCE345]"} fullWidth={true}>Подтвердить</Button>
                                                </div>
                                            </ModalFooter>
                                        }
                                    </>
                                )
                            }}
                        </ModalContent>
            </Modal>
        </>)}
