import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button, Select, SelectItem, Textarea
} from "@nextui-org/react";
import {useState} from "react";
import {useNavigate, useParams} from "react-router";
import axios from "axios";
import {BaseURL} from "../../variables/Variables";


export const BuyForm = () => {
    const {id} = useParams();
    console.log(id);
    const [payBill, setPayBill] = useState(null);
    const [attachment, setAttachment] = useState([
    ]);
    const [attachments, setAttachments] = useState([
        0 ,
    ]);
    const [sendData, setSendData] = useState({
        "name": "",
        "comment": "",
        "delivery_date": "",
        "supplier": ""
    })
    const history = useNavigate();
    const [rows, setRows] = useState([{
        "id": "",
        "name": "",
        "pack": "",
        "count": 0,
        "price": 0,
        "sum": "",
    }]);
    const columns = [
        {
            key: "id",
            label: "Артикул",
        },
        {
            key: "name",
            label: "Наименование товара / услуги",
        },
        {
            key: "count",
            label: "Количество",
        },
        {
            key: "pack",
            label: "Упаковка",
        },
        {
            key: "price",
            label: "Цена за единицу",
        },
        {
            key: "sum",
            label: "Сумма",
        },
        {
            key: "delete",
            label: "Удалить?",
        }
    ];
    const handleCost = () => {
        const temp_rows = [...rows]
        setRows(temp_rows);
    }

    const deleteRow = (index) => {
        const temp_rows = [...rows]
        temp_rows.splice(index, 1);
        setRows(temp_rows);
    }
    const changeInput = (e, index, name) => {
        const temp_rows = [...rows];
        temp_rows[index][`${name}`] = e.target.value;
        if (name === "count" || name === "price"){
            temp_rows[index].sum = Math.floor(temp_rows[index].count * temp_rows[index].price * 100) / 100;
        }
        setRows(temp_rows);
    }

    const submitData = e => {
        e.preventDefault();
        console.log(rows);
        console.log(payBill);
        const data = new FormData();

        data.append("name", sendData.name);
        data.append("template_id", id);
        data.append("comment", sendData.comment);
        data.append("delivery_date", sendData.delivery_date);
        data.append("supplier", sendData.supplier);
        data.append("rows", JSON.stringify(rows));
        data.append("template", JSON.stringify(""));
        data.append("pay_bill", payBill);

        if (attachment){
            Array.from(attachment).forEach((file, index) => {
                data.append(`attachment_${index}`, file);
            });
        }

        const headers = {'Content-Type': 'multipart/form-data'};

        axios.post(`${BaseURL}/users/approve/create/`, data, {headers}).then(
            res => {
                history("/")
            }
        ).catch(
            err => {console.log(err)}
        )
    }

    return(
        <div className={"py-[12px] flex flex-col gap-[12px] items-start"}>
            <Button onPress={() => {history('/')}}>
                Назад
            </Button>
            <form onSubmit={submitData} className={"flex flex-col gap-[16px]"}>
                <Input
                    label={"Название"}
                    required={true}
                    type={"text"}
                    name={"name"}
                    onChange={(e) => {setSendData({...sendData, name: e.target.value})}}
                />
                <Input
                    label={"Поставщик"}
                    required={true}
                    type={"text"}
                    name={"supplier"}
                    onChange={(e) => {setSendData({...sendData, supplier: e.target.value})}}
                />
                <Input
                    label={"Ожидаемая дата поставки"}
                    required={true}
                    type={"date"}
                    name={"delivery_date"}
                    onChange={(e) => {setSendData({...sendData, delivery_date: e.target.value})}}
                />
                <Textarea
                    label={"Комментарий и инструкция"}
                    required={true}
                    type={"text"}
                    name={"comment"}
                    onChange={(e) => {setSendData({...sendData, comment: e.target.value})}}
                />
                <div>
                    <label className={"text-white font-bold"}>Прикрепите пожалуйста счет на оплату (для прикрепления нажмите на белую зону)</label>
                    <Input
                        label={""}
                        type={"file"}
                        name={"comment"}
                        onChange={(e) => {
                            setPayBill(e.target.files[0]);
                        }
                    }
                    />
                </div>
                <div className={"flex flex-col gap-[12px]"}>
                    <label className={"text-white font-bold"}>Прикрепите дополнительные файлы (При наличии)</label>
                    <div className={"flex flex-row gap-[16px]"}>
                        <Input
                            label={""}
                            type={"file"}
                            name={"attachment"}
                            multiple={true}
                            onChange={(e) => {
                                setAttachment(e.target.files);
                            }
                            }
                        />
                    </div>
                </div>
                <div className={"overflow-auto w-full max-w-[300px] md:max-w-full"}>
                    <Table aria-label="Example table with dynamic content">
                        <TableHeader columns={columns}>
                            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                        </TableHeader>
                        <TableBody>
                            {
                                rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={"rounded-none"}>
                                            <Input
                                                required={true}
                                                value={row.id}
                                                onChange={e => {changeInput(e, index, "id")}}
                                            />
                                        </TableCell>
                                        <TableCell className={"rounded-none"}>
                                            <Input
                                                required={true}
                                                value={row.name}
                                                onChange={e => {changeInput(e, index, "name")}}
                                            />
                                        </TableCell>
                                        <TableCell className={"rounded-none"}>
                                            <Input
                                                required={true}
                                                value={row.count}
                                                type={"number"}
                                                onChange={e => {changeInput(e, index, "count");
                                                    handleCost();}}
                                            />
                                        </TableCell>
                                        <TableCell className={"rounded-none"}>
                                            <Select
                                                required={true}
                                                value={""}
                                                onChange={e => {
                                                    row.pack = e.target.value
                                                }}
                                                className="max-w-xs w-[220px]"
                                            >
                                                <SelectItem key={"-"} value={"-"}>-</SelectItem>
                                                <SelectItem key={"Услуга"} value={"Услуга"}>Услуга</SelectItem>
                                                <SelectItem key={"г."} value={"г."}>г.</SelectItem>
                                                <SelectItem key={"Кг."} value={"Кг."}>Кг.</SelectItem>
                                                <SelectItem key={"Шт."} value={"Шт."}>Шт.</SelectItem>
                                                <SelectItem key={"Литр"} value={"Литр"}>Литр</SelectItem>
                                                <SelectItem key={"Банка"} value={"Банка"}>Банка</SelectItem>
                                                <SelectItem key={"Упаковка"} value={"Упаковка"}>Упаковка</SelectItem>
                                                <SelectItem key={"Пачка"} value={"Пачка"}>Пачка</SelectItem>
                                                <SelectItem key={"м"} value={"м"}>Метр</SelectItem>
                                                <SelectItem key={"м2"} value={"м2"}>Квадратный метр</SelectItem>
                                                <SelectItem key={"Пог. м"} value={"Пог. м"}>Погонный метр</SelectItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell className={"rounded-none"}>
                                            <Input
                                                required={true}
                                                value={row.price}
                                                type={"number"}
                                                onChange={e => {changeInput(e, index, "price")
                                                    handleCost();}}
                                            />
                                        </TableCell>
                                        <TableCell className={"rounded-none"}>
                                            <Input
                                                disabled={true}
                                                value={row.sum}
                                            />
                                        </TableCell>
                                        <TableCell className={"rounded-none"}>
                                            {
                                                index > 0 && <Button onPress={() => {deleteRow(index)}}>Удалить</Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>

                <div className={"flex gap-[12px]"}>
                    <Button onPress={() => {
                        const temp_rows = [...rows]
                        temp_rows.push({
                            "id": "",
                            "name": "",
                            "pack": "",
                            "count": 0,
                            "price": 0,
                            "sum": ""
                        });
                        setRows(temp_rows);
                    }}>
                        Добавить строчку
                    </Button>
                    <Button
                        type={"submit"}>
                        Отправить на проверку
                    </Button>
                </div>
            </form>
        </div>
    )
}
